import axios, { AxiosError } from 'axios';
import { getToken } from '../repository/cognito';
import { ApiConfig } from "../config";
import { Toast } from '../components';
import { pickBy } from 'lodash';
import {
	BankAccountBalanceType,
	BankAccountStatementType,
	BankAccountsType, BankSlipType, BeneficiaryType, CreateBeneficiaryType,
	QueryFiltersType, TransactionType, CreateTransferType,
	CreateOperatorType,
	ParamsCancel,
	ParamsApproveOrReject,
	ParamsDelete
} from "../global/types/digitalAccount.types";
import { PaginatedRequest, PaginationSelfType, PaymentType } from "../global/types/types";
import Helper from "helpers/message.helpers";

export const getDigitalBankAccountActiveList = async (filters?: QueryFiltersType, tenant?: string): Promise<PaginatedRequest<BankAccountsType[] & { balance: number }[]>> => {

	//@ts-ignore
	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;

	const token = await getToken();
	var url = ApiConfig.baseURL + `/BankAccount`;
	if (tenant) url = url + "?tenant=" + tenant;


	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	let result;

	try {
		result = await axios.get(url, config);
		const { data } = result;

		const promises = data.data.map(async (account: any) => {
			const accountBalance = await getBankAccountBalance(account.id);

			return { ...account, balance: accountBalance.available || 0 };
		});

		const bankAccounts = await Promise.all(promises);
		return { ...data, data: bankAccounts };
	} catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors("Não foi possível obter a lista de contas ativas.", error);
		throw error;
	}
};

export const getBankAccountOperatorList = async (accountId: string, filters: any) => {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;

	const token = await getToken();
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Operator`;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	try {
		const response = await axios.get(url, config);
		return response.data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors("Não foi possível obter a lista de operadores.", error);
		throw error;
	}
};

export const createOperator = async (accountId: string, payload: CreateOperatorType, tenant: string) => {

	const token = await getToken();
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Operator`;

	const urlWithTenant = tenant ?
		url + "?tenant=" + tenant :
		url;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.post(urlWithTenant, payload, config);
		const { data } = response;
		Toast.showSuccessMessage("Operador adicionado a conta com sucesso.");
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível cadastrar o operador.', error);
		throw error;
	}
};

export const getBankAccountStatement = async (accountId: string, filters?: QueryFiltersType): Promise<BankAccountStatementType & { transactions: TransactionType[] }> => {

	const token = await getToken();
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Statement`;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params: { ...filters }
	};

	try {
		const response = await axios.get<BankAccountStatementType>(url, config);
		const { data } = response;

		const transactions: TransactionType[] = [];
		data.balances.forEach((statement) => {
			statement.transactions.forEach((transaction) => transactions.push(transaction));
		});
		return { ...data, transactions };
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors("Não foi possível obter a lista de extrato.", error);
		throw error;
	}
};

export const getBankAccountBalance = async (accountId: string): Promise<BankAccountBalanceType> => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Balance`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.get<BankAccountBalanceType>(url, config);
		const { data } = response;

		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors("Não foi possível obter o saldo da conta.", error);
		throw error;
	}
};

export const getBeneficiariesList = async (accountId: string, filters: any) => {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	delete params.orderByField;
	delete params.orderByDirection;

	var token = await getToken();
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Beneficiary`;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	try {
		const response = await axios.get<PaginatedRequest<BeneficiaryType[]> & PaginationSelfType>(url, config);
		const { data } = response;
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível obter a lista  de favorecidos.', error);
		throw error;
	}
};

export const createBeneficiary = async (accountId: string, payload: CreateBeneficiaryType) => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Beneficiary`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	delete payload.bankDisplay;
	//@ts-ignore
	payload = {
		...payload,
		registrationNumber: payload.registrationNumber.replace(/\D/g, ''),
		//@ts-ignore
		keyPix: payload.pixKeyTypeValue === "AgencyAndAccount" ? payload.bank + payload.agency + payload.accountNumber?.replace(/\D/g, '') : payload.keyPix
	};

	try {
		const response = await axios.post(url, payload, config);
		Toast.showSuccessMessage("Favorecido cadastrado com sucesso.");
		return response.data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível cadastrar o favorecido.', error);
		throw error;
	}
};

export const getPaymentsList = async (accountId: string, filters: any): Promise<PaginatedRequest<PaymentType[]>> => {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));

	delete params.orderByField;
	delete params.orderByDirection;

	var token = await getToken();
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Pay`;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	try {
		const response = await axios.get<PaginatedRequest<PaymentType[]>>(url, config);
		const { data } = response;
		if (data) {
			data.data.forEach((item) => {
				item.amount = Number(item?.amount) / 100;
			});
		}
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível obter a lista de pagamentos.', error);
		throw error;
	}

};

export const postPay = async (accountId: string, payload: BankSlipType) => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Pay`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.post(url, payload, config);
		Toast.showSuccessMessage("Pagamento realizado com sucesso!");
		const { data } = response;
		return data;

	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível efetuar o pagamento.', error);
		throw error;
	}
};

export const getBankSlipData = async (accountId: string, data: any) => {

	const { barCode } = data;

	let digitableLine = barCode.length >= 47 ? barCode : null;


	let url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Pay/Bankslip`;
	barCode.length === 44 ? url = url + `?barCode=${barCode}` : url = url + `?digitableLine=${digitableLine}`;

	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

	try {
		const response = await axios.get(url, config);
		Toast.showSuccessMessage("Dados do boleto obtidos com sucesso.");
		return response.data;

	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível obter os dados do boleto.', error);
		throw error;
	}
};

export const getTransfersList = async (accountId: string, filters: any) => {

	filters.orderBy = filters?.orderByField != undefined ? filters?.orderByField + "_" + filters?.orderByDirection.toUpperCase() : undefined;
	const params = pickBy(filters, v => (v !== undefined && v !== '' && v !== false));
	delete params.orderByField;
	delete params.orderByDirection;
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Transfer`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params
	};

	try {
		const response = await axios.get(url, config);
		const { data } = response;
		if (data) {
			data.data.forEach((item: { amount: number; }) => {
				item.amount = Number(item.amount) / 100;
			});
		};
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível obter as lista de transferência.', error);
		throw error;
	}
};

export const createTransfer = async (accountId: string, data: CreateTransferType) => {
	const url = `${ApiConfig.baseURL}/BankAccount/${accountId}/Transfer`;
	const token = await getToken();

	const payload = {
		...data,
		amount: data.amount * 100
	};

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.post(url, payload, config);
		Toast.showSuccessMessage("Transferência realizada com sucesso.");
		const { data } = response;

		return data;
	} catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível realizar a transferência.', error);
		throw error;
	}
};

export const approveOrReject = async (props: ParamsApproveOrReject) => {
	const { bankAccountId, payload, paymentId, path } = props;
	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${path}/${paymentId}/approve`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.put(url, payload, config);
		const { data } = response;

		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Ocorreu um erro ao atualizar o pagamento.', error);
		throw error;
	}
};

export const deleteListOperator = async (props: ParamsDelete) => {
	const { bankAccountId, userId, sessionId, passCode, path } = props;
	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${path}/${userId}?sessionId=${sessionId}&code=${passCode}`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.delete(url, config);
		return response.data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível remover o operador.', error);
		throw error;
	}
};

export const deleteListFavored = async (props: ParamsDelete) => {
	const { bankAccountId, userId, sessionId, passCode, path } = props;
	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${path}/${userId}?sessionId=${sessionId}&code=${passCode}`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.delete(url, config);
		return response.data;
	}
	catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Não foi possível remover o favorecido.', error);
		throw error;
	}
};

export const cancel = async (props: ParamsCancel) => {
	const { bankAccountId, beneficiaryId, sessionId, passCode, path } = props;

	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${path}/${beneficiaryId}/cancel?sessionId=${sessionId}&code=${passCode}`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	try {
		const response = await axios.put(url, {}, config);
		const { data } = response;
		return data;

	} catch (e) {
		const error = e as AxiosError;
		Helper.parseErrors('Ocorreu um erro ao cancelar.', error);
		throw error;
	}
};


export const generateSessionId = async (username: string, password: string, poolId: string, cognitoClientId: string) => {

	const url = `${ApiConfig.baseURL}/Auth/StartAuth`;
	const token = await getToken();

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const payload = {
		username: username,
		password: password,
		poolId: poolId,
		cognitoClientId: cognitoClientId
	};

	try {
		const response = await axios.post(url, payload, config);
		const { data } = response;
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		if(error.message) {
			Helper.parseErrors('Senha incorreta.', error.message);
		} else {
			Helper.parseErrors('Não foi possível iniciar a sessão.', error);
		}
		throw error;
	}
};

export const postTransferreceipt = async (bankAccountId: string, beneficiaryId: string) => {

	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${beneficiaryId}/transferreceipt`;
	const token = await getToken();

	const config: any = {
		headers: { Authorization: `Bearer ${token}` },
		responseType: 'blob',
	};

	try {
		const response = await axios.post(url, {}, config);
		const { data } = response;
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		if (error.message) {
			Helper.parseErrors('Comprovante de transferência indisponível', error.message);
		} else {
			Helper.parseErrors('Não foi possível obter o comprovante da transferência.', error);
		}
		throw error;
	}
};

export const postPaymentreceipt = async (bankAccountId: string, beneficiaryId: string) => {

	const url = `${ApiConfig.baseURL}/BankAccount/${bankAccountId}/${beneficiaryId}/paymentreceipt`;
	const token = await getToken();

	const config: any = {
		headers: { Authorization: `Bearer ${token}` },
		responseType: 'blob'
	};

	try {
		const response = await axios.post(url, {}, config);
		const { data } = response;
		return data;
	}
	catch (e) {
		const error = e as AxiosError;
		if (error.message) {
			Helper.parseErrors('Comprovante de pagamento indisponível', error.message);
		} else {
			Helper.parseErrors('Não foi possível obter o comprovante de pagamento.', error);
		}
		throw error;
	}
};