import React, { forwardRef, useEffect, useState, useCallback } from "react";
import {
	Box,
	Button,
	TextField,
	Select,
	MenuItem,
	Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { localizationOptions } from "helpers/table.helpers";
import * as personService from "services/person";
import { Autocomplete } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import debounce from "random/debounce";
import { useTenant } from "contexts/tenant";
import Helper from "helpers/format.helpers";
import { TablePagination } from "@material-ui/core";
import { TableContainer } from "containers/TableContainer";

const SearchPersonFields = ({ related, onRowDataChange, rowData }) => {
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == "0";
	const [searchField, setSearchField] = useState(undefined);
	const [options, setOptions] = useState([]);
	const { selectedTenant } = useTenant();

	const verify = useCallback(
		debounce((name) => {
			fetchPersons(name);
		}, 500),
		[]
	);

	useEffect(() => {
		fetchPersons();
	}, []);

	useEffect(() => {
		verify(searchField);
	}, [searchField]);

	const fetchPersons = (name) => {
		personService
			.getPersonsList({ page: 0, size: 10, searchString: name }, selectedTenant)
			.then((resp) => {
				setOptions(
					resp?.data.map((n) => {
						return {
							name: isRootTenancy
								? `${n.name || "Não informado"} (${
									n.tenantDisplay
								  }) - (${Helper.formatDocumentNumber(n.registrationNumber)})`
								: n.name,
							value: n.id,
						};
					})
				);
			});
	};

	return (
		<Autocomplete
			fullWidth
			noOptionsText={"Nenhum registro foi encontrado."}
			defaultValue={
				related
					? { value: rowData.relatedToId, name: rowData.relatedToIdDisplay }
					: { value: rowData.personId, name: rowData.personIdDisplay }
			}
			options={options}
			getOptionLabel={(option) => option.name}
			filterOptions={(options, _) => options}
			renderInput={(params) => (
				<TextField
					{...params}
					onChange={(event) => setSearchField(event?.target?.value)}
					shrink
				/>
			)}
			onFocus={() => setSearchField("")}
			onInputChange={(_, __, reason) => {
				if (reason === "clear") setSearchField("");
			}}
			onChange={(event, person) => {
				if (related) {
					onRowDataChange({
						...rowData,
						relatedToId: person?.value,
						relatedToIdDisplay: person?.name,
					});
				} else {
					onRowDataChange({
						...rowData,
						personId: person?.value,
						personIdDisplay: person?.name,
					});
				}
			}}
		/>
	);
};

const typeOfRelationships = [
	{ name: "Anuente", value: "Consenter" },
	{ name: "Avalista", value: "Guarantor" },
	{ name: "Beneficiário", value: "Beneficiary" },
	{ name: "Cedente", value: "Assignor" },
	{ name: "Cessionário", value: "Assignee" },
	{ name: "Cônjuge", value: "Spouse" },
	{ name: "Credor / Endossante", value: "CreditorEndorser" },
	{ name: "Empregador", value: "Payer" },
	{ name: "Endossante", value: "Endorser" },
	{ name: "Procurador", value: "Proxy" },
	{ name: "Procurador / Avalista", value: "ProxyGuarantor" },
	{ name: "Sócio", value: "Partner" },
	{ name: "Sócio / Avalista", value: "PartnerGuarantor" },
	{ name: "Testemunha", value: "Witness" },
];

const signatureTypes = [
	{ name: "Email", value: "email" },
	{ name: "Sms", value: "sms" },
	{ name: "Whatsapp", value: "whatsapp" },
];

const signatureValidations = [
	{ name: "Assinatura Eletrônica", value: "EletronicSignature" },
	{ name: "Assinatura com Selfie", value: "SelfieSignature" },
	{ name: "Selfie + Documento Oficial", value: "SelfieDocumentSignature" },
	{ name: "Biometria Facial", value: "FacialBiometrics" },
	{ name: "Certificado Digital", value: "DigitalCertificate" },
];

function InvolvedPersonsTab(props) {
	console.log("props", props);

	const { involvedPersons, setInvolvedPersons } = props;
	const [isEditingTable, setIsEditingTable] = React.useState(false);
	const addPersonButton = React.useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(
		localStorage.getItem("rowsPerPage") || 5
	);

	useEffect(() => {
		if (involvedPersons.length < 1) {
			addPersonButton.current.click();
			setIsEditingTable(true);
		}
	}, []);

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem("rowsPerPage", page);
	};

	const table = (
		<MaterialTable
			title={
				<Button
					onClick={() => {
						addPersonButton.current.click();
						setIsEditingTable(true);
					}}
					variant="contained"
					color="primary"
					disabled={isEditingTable}
				>
					<Typography
						variant="h7"
						component="span"
						style={{ color: "white", textTransform: "none" }}
					>
						+ envolvido
					</Typography>
				</Button>
			}
			icons={{
				Add: (props) => <div ref={addPersonButton}></div>,
				Edit: forwardRef((props, ref) => {
					return (
						<Edit
							{...props}
							onClick={() => {
								setTimeout(() => {
									setIsEditingTable(!!isEditingTable);
								}, 10);
							}}
							ref={ref}
						/>
					);
				}),
			}}
			editable={{
				onRowAdd: (newData) =>
					new Promise((resolve, reject) => {
						setIsEditingTable(false);
						setInvolvedPersons([...involvedPersons, newData]);
						resolve();
					}),
				onRowUpdate: (newData, oldData) => {
					return new Promise((resolve) => {
						let index = involvedPersons
							.map((f) => (f.id ? f.id : f.tableData.id))
							.indexOf(oldData.id ? oldData.id : oldData.tableData.id);
						let newInvolvedPersons = [...involvedPersons];
						newInvolvedPersons[index] = newData;
						setIsEditingTable(false);
						setInvolvedPersons(newInvolvedPersons);
						resolve();
					});
				},
				onRowAddCancelled: () => {
					return new Promise((resolve) => {
						setIsEditingTable(false);
						resolve();
					});
				},
				onRowUpdateCancelled: () => {
					setIsEditingTable(false);
				},
				onRowDelete: (rowData) =>
					new Promise((resolve) => {
						let currentPerson = involvedPersons;
						let index = currentPerson
							?.map((f) => f.personId)
							.indexOf(rowData.personId);
						currentPerson.splice(index, 1);
						setInvolvedPersons([...currentPerson]);
						resolve();
					}),
			}}
			columns={[
				{
					title: "Pessoa",
					field: "personIdDisplay",
					editComponent: (props) => (
						<SearchPersonFields
							onRowDataChange={props.onRowDataChange}
							rowData={props.rowData}
						/>
					),
				},
				{
					title: "Tipo de relação",
					field: "typeOfRelationship",
					render: ({ typeOfRelationship }) =>
						typeOfRelationships?.find(
							(asset) => asset.value == typeOfRelationship
						)?.name,
					editComponent: (props) => {
						const findedItem = typeOfRelationships.find(
							(item) => item.value === props.value
						);
						return (
							<Select
								value={props.value || ""}
								fullWidth
								onChange={(event) => props.onChange(event.target.value)}
								component={TextField}
							>
								<MenuItem value={props.value}>{findedItem?.name}</MenuItem>
								{typeOfRelationships &&
									typeOfRelationships.map((field) => (
										<MenuItem key={field.value} value={field.value}>
											{field.name}
										</MenuItem>
									))}
							</Select>
						);
					},
				},
				{
					title: "Forma de Envio",
					field: "signatureType",
					render: ({ signatureType }) =>
						signatureTypes?.find((asset) => asset.value == signatureType)?.name,
					editComponent: (props) => {
						return (
							<Select
								value={props.value || ""}
								fullWidth
								onChange={(event) => props.onChange(event.target.value)}
								component={TextField}
							>
								{signatureTypes &&
									signatureTypes.map((field) => (
										<MenuItem key={field.value} value={field.value}>
											{field.name}
										</MenuItem>
									))}
							</Select>
						);
					},
				},
				{
					title: "Forma de Validação",
					field: "signatureValidation",
					render: ({ signatureValidation }) =>
						signatureValidations?.find(
							(asset) => asset.value == signatureValidation
						)?.name,
					editComponent: (props) => {
						return (
							<Select
								value={props.value || ""}
								fullWidth
								onChange={(event) => props.onChange(event.target.value)}
								component={TextField}
							>
								{signatureValidations &&
									signatureValidations.map((field) => (
										<MenuItem key={field.value} value={field.value}>
											{field.name}
										</MenuItem>
									))}
							</Select>
						);
					},
				},
			]}
			data={involvedPersons}
			onChangeRowsPerPage={onRowsPerPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
			localization={localizationOptions}
			components={{
				Pagination: (props) => (
					<TablePagination {...props} rowsPerPageOptions={[5, 10, 15]} />
				),
			}}
			options={{
				actionsColumnIndex: -1,
				pageSize: rowsPerPage,
				toolbarButtonAlignment: "left",
				searchAutoFocus: true,
			}}
		/>
	);

	return (
		<>
			<Box p={1}>
				<TableContainer>{table}</TableContainer>
			</Box>
		</>
	);
}

export default InvolvedPersonsTab;
