import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, Toast } from 'components';
import { AppBar, Tabs, Tab, Grid, Box } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import InformationsBankAccount from 'pages/BankAccountRequest/FieldsForm/informationsBankAccount';
import { getValidationSchema, defaultValues } from 'pages/BankAccountRequest/FieldsForm/schema/validation';
import * as bankAccountService from 'services/bankAccount';
import { useLoading } from 'contexts/loading';
import InvolvedPersons from 'pages/BankAccountRequest/Tabs/involvedPerson';
import { useDropzone } from 'react-dropzone';
import Upload from 'pages/BankAccountRequest/Tabs/upload';
import RecordTimeline from 'components/Timeline/RecordTimeline';
import ModalApproveAndReject from '../ModalApproveAndReject/approveAndReject';
import { useHeader } from 'contexts/header';
import KYC from '../KYC/kyc';
import ModalManualUpdate from 'components/RequestManualUpdate/ManualUpdateComponent';
import { Alert } from '@material-ui/lab';
import ModalComponent from 'components/Modal/ModalComponent';

function BankAccoutEdit(props) {

	const history = useHistory();
	const { setTitle } = useHeader();
	const { loading, addLoader, completeLoader } = useLoading();
	const [bankAccount, setBankAccount] = useState();
	const [files, setFiles] = useState([]);
	const [modalUpdate, setModalManualUpdate] = useState(false);
	const [tab, setTab] = useState('Informações');
	const [envWithProdAccount, setEnvWithProdAccount] = useState([]);
	const [involvedPersons, setInvolvedPersons] = useState([]);
	const [openModalApproveAndReject, setOpenModalApproveAndReject] = useState(null);
	const [modalDelete, setModalDelete] = useState(false);
	const { getRootProps, getInputProps } = useDropzone({});
	const { handleSubmit, setValue, watch, getValues, control, reset, formState: { isDirty } } = useForm({
		resolver: yupResolver(getValidationSchema),
		defaultValues: defaultValues
	});

	const getBankAccountId = () => props?.location?.search?.replace('?id=', '');
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';
	const { errors } = useFormState({ control });
	const { isFieldRequired, inputBind, inputBindNumber } = getFormFunctions({ validationSchema: getValidationSchema, setValue, control, errors });

	const isEditing = () => {
		return !!getBankAccountId();
	};

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const readOnly = (isEditing()) ? !['Draft', 'Error', 'Revision', 'Disapproved', 'CorbanPreAnalysis'].includes(bankAccount?.status) : false;

	useEffect(() => {
		if (isEditing()) {
			fetchBankAccountsById();
		} else {
			setTitle("Conta Digital - nova conta digital");
		}
	}, []);

	useEffect(() => {
		if (bankAccount) {
			reset(bankAccount);
		}
	}, [bankAccount]);

	const fetchBankAccountsById = () => {
		addLoader("fetchBankAccountsById");
		bankAccountService.getBankAccountRequestById(getBankAccountId())
			.then(result => {
				completeLoader("fetchBankAccountsById");
				setBankAccount(result);
				setFiles(result?.uploads || []);
				setInvolvedPersons(result?.relatedPerson);
				setTitle(`Conta Digital - ${result?.ownerDisplay}`);
			}).catch(err => completeLoader("fetchBankAccountsById"));
	};

	const saveBankAccount = (values) => {

		const data = {
			...values,
			uploads: files,
			relatedPerson: involvedPersons
		};

		const option = isEditing() ? 'edit' : 'create';
		bankAccountService.editOrCreate(data, option)
			.then(result => {
				if (result?.data) {
					history.push(`/solicitacao-conta-digital/editar?id=${result.data}`);
				} else {
					fetchBankAccountsById();
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const sendApproval = async () => {
		addLoader("sendApproval");
		bankAccountService.sumbitApprovalById(getBankAccountId())
			.then(response => {
				completeLoader("sendApproval");
				history.push('/solicitacao-conta-digital');
			})
			.catch(err => {
				completeLoader("sendApproval");

			});
	};

	const sendApprovalRevision = async () => {
		addLoader("sendApprovalRevision");
		bankAccountService.approvalRevisionById(getBankAccountId())
			.then(response => {
				completeLoader("sendApprovalRevision");
				history.push('/solicitacao-conta-digital');
			})
			.catch(err => {
				completeLoader("sendApprovalRevision");

			});
	};

	const handleDelete = async () => {
		addLoader("handleDelete");
		bankAccountService.deleteBankAccount(getBankAccountId()).finally(() => {
			completeLoader("handleDelete");
			history.push('/solicitacao-conta-digital');
		});
	};

	const showSendApproval = (status) => {
		if (
			status &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_SEND_CREDIT_APPROVAL &&
			(['Draft', 'Timeout', "Cancel", "CorbanPreAnalysis"].includes(status))
		) return true;

		return;
	};

	const handleCloseModalApproveAndReject = () => {
		setOpenModalApproveAndReject(null);
	};

	const handleModalApprove = () => {
		setOpenModalApproveAndReject(true);
	};

	const handleModalReject = () => {
		setOpenModalApproveAndReject(false);
	};

	const handleModalManualUpdate = () => {
		setModalManualUpdate(true);
	};
	const handleCloseModalManualUpdate = () => {
		setModalManualUpdate(false);
	};

	const handleModalDelete = () => {
		setModalDelete(true);
	};

	const handleCloseModalDelete = () => {
		setModalDelete(false);
	};

	const showApproveAndOnReject = (status) => {
		if (
			status &&
			isRootTenancy && !!+window.__RUNTIME_CONFIG__.REACT_APP_APPROVE_REJECT_CREDIT &&
			['Approval'].includes(status)
		) return true;
		return;
	};

	const showSendApproveRevision = (status) => {
		if (
			status &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_SEND_CREDIT_APPROVAL &&
			(["OpeningAccountRevision"].includes(status))
		) return true;

		return;
	};

	const showDelete= (status) => {
		if (
			status &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_SEND_CREDIT_APPROVAL &&
			(['Draft', 'Revision'].includes(status))
		) return true;

		return;
	};

	const actions = isEditing() ? {
		onSendApprovalRevision: showSendApproveRevision(bankAccount?.status) ? sendApprovalRevision : false,
		onSendApproval: showSendApproval(bankAccount?.status) ? sendApproval : false,
		onApprove: showApproveAndOnReject(bankAccount?.status) ? handleModalApprove : false,
		onReject: showApproveAndOnReject(bankAccount?.status) ? handleModalReject : false,
		disableOnApproveInstrument: showApproveAndOnReject(bankAccount?.status),
		onManualUpdate: handleModalManualUpdate,
		onDelete: showDelete(bankAccount?.status) ? handleModalDelete : false,
		disabledAll: loading
	} : {
	};

	const taker = { ownerDiscriminator: watch("ownerDiscriminator"), ownerId: watch("ownerId"), ownerDisplay: watch('ownerDisplay') };

	const filterInvolvedPersons = bankAccount?.relatedPerson.filter((value) => {
		return value.typeOfRelationship != "Witness";
	});

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsBankAccount {...{ isEditing, bankAccount, inputBind, inputBindNumber, control, setValue, getValues, watch, readOnly, setEnvWithProdAccount }} />
		},
		{
			name: 'Envolvidos',
			show: true,
			children: <InvolvedPersons {...{ inputBindNumber, isFieldRequired, inputBind, involvedPersons, setInvolvedPersons, readOnly, envWithProdAccount }} />
		},
		{
			name: 'Documentos',
			show: true,
			children: <Upload  {...{ readOnly, files, setFiles, taker, getRootProps, getInputProps, involvedPersons }} />
		},
		{
			name: 'KYC',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_RISK_TAB && isRootTenancy && isEditing(),
			children: <KYC {... { bankAccountId: getBankAccountId(), isEditing, setLoading: (side) => { side ? addLoader("KYC") : completeLoader("KYC"); }, involvedPersons: filterInvolvedPersons, taker }} />
		},
		{
			name: 'Linha do tempo',
			show: isEditing(),
			children: <RecordTimeline data={bankAccount?.timeline} />
		}
	];


	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const onSubmit = (values) => {
		saveBankAccount(values);
	};
	const id = getBankAccountId();

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 7, borderBottomLeftRadius: 10, borderBottomRightRadius: 7, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<ContentContainer>
			{openModalApproveAndReject != null &&
				<ModalApproveAndReject onCloseModal={handleCloseModalApproveAndReject} id={id} approve={openModalApproveAndReject} />
			}
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			{modalUpdate &&
				<ModalManualUpdate
					open={modalUpdate}
					recordType={"BankAccountRequest"}
					recordId={getBankAccountId()}
					handleOpen={handleModalManualUpdate}
					onCloseModal={handleCloseModalManualUpdate} />
			}
			{modalDelete &&
				<>
					<ModalComponent
						open={modalDelete}
						onClose={handleCloseModalDelete}
						title="Excluir conta bancária"
						subtitle="tem certeza que deseja excluir esta conta bancária?"
						buttonText="Excluir"
						children={<></>}
						onClick={() => handleDelete()} />
				</>
			}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<Grid container direction="row">
					<Grid item xs={12} lg={12}>
						<ActionBar {...actions} />
					</Grid>
					{isEditing() && <>
						{isDirty ? <Grid item style={{ display: 'flex', justifyContent: 'flex-start' }} >
							<Alert severity="warning">Existem informações não salvas</Alert>
						</Grid> :
							<div className="disablePrint" style={{ width: "100%" }}>
								<Box p={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									{bankAccount?.statusDisplay && <small style={style}><strong>Status:</strong>&nbsp;{bankAccount?.statusDisplay}</small>}
									{bankAccount?.createdAt && <small style={style}><strong>Criado em:</strong>&nbsp;{bankAccount?.createdAt && new Date(bankAccount?.createdAt).toLocaleDateString('pt-BR')}</small>}
									{bankAccount?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{bankAccount?.ownerUser?.userIdDisplay}</small>}
									{bankAccount?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{bankAccount?.ownerGroup?.groupDisplay}</small>}
								</Box>
							</div>}</>
					}

				</Grid>

				<AppBar position="relative" color="transparent" elevation={0}>
					<Tabs
						centered={false}
						value={tab}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((item, index) => (
							item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
}

export default BankAccoutEdit;
