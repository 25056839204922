import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentContainer, ModalWithLoading, Toast } from 'components';
import * as creditNoteService from 'services/creditNote';
import { getLegalPersonById } from 'services/legalPerson';
import { getNaturalPersonById } from 'services/naturalPerson';
import { Box, AppBar, Tabs, Tab, Grid, TextField, Button } from '@material-ui/core';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionBar from 'components/ActionBar/ActionBar';
import TabPanel, { a11yProps } from 'components/TabPanel/TabPanel';
import getFormFunctions from 'helpers/form-helpers';
import { useDropzone } from 'react-dropzone';
import * as amortizationSimulation from 'services/amortization';
import RecordTimeline from 'components/Timeline/RecordTimeline';
import InformationsCredit from '../FieldsForm/informationsCredit';
import OperationsContent from '../Tabs/operationsContent';
import UploadContent from '../Tabs/uploadContent';
import InvolvedPersons from '../Tabs/involvedPersons';
import WarrantyTab from '../Tabs/WarrantyTab';
import CreditNoteDatasets from '../Datasets/datasets';
import { Alert } from '@material-ui/lab';
import { useLoading } from 'contexts/loading';
import { useHeader } from 'contexts/header';
import { useTenant } from 'contexts/tenant';
import KYC from '../KYC/kyc';
import { useProduct } from 'contexts/product';
import { useFund } from 'contexts/fund';
import ModalBlock from '../ReasonBlock/reasonBlock';
import ModalAssign from 'components/Assign/ModalAssign';
import ModalManualUpdate from 'components/RequestManualUpdate/ManualUpdateComponent';
import { getDefaultValues, getValidationSchema } from '../FieldsForm/schema/validation';
import ModalComponent from 'components/Modal/ModalComponent';
import ModalGenereteLink from 'components/ModalGenereteLink/ModalGenereteLink';
import { ModalApproveLiquidation } from 'components/ModalApproveLiquidation/ModalApproveLiquidation';

function CreditNoteCreateEdit(props) {

	const { getOptionById } = useProduct();
	const { setTitle } = useHeader();
	const { selectedTenant } = useTenant();
	const { loading, addLoader, completeLoader } = useLoading();
	const [tab, setTab] = React.useState('Informações');
	const [simulationResponse, setSimulationResponse] = useState(null);
	const [product, setProduct] = useState(null);
	const { control, getValues, handleSubmit, trigger, setValue, reset, watch, formState: { isDirty } } = useForm({
		resolver: yupResolver(getValidationSchema(product)),
		defaultValues: getDefaultValues(product)
	});
	const [bankAccountPerson, setBankAccountPerson] = useState([]);
	const [bankAccountBeneficiary, setBankAccountBeneficiary] = useState([]);
	const [files, setFiles] = useState([]);
	const [involvedPersons, setInvolvedPersons] = useState([]);
	const { getRootProps, getInputProps } = useDropzone({});
	const history = useHistory();
	const [creditNote, setCreditNote] = useState();
	const [dataNaturalOrLegalPerson, setDataNaturalOrLegalPerson] = useState();
	const [sendEmail, setSendEmail] = useState(false);
	const [modalAssign, setModalAssign] = useState(false);
	const [modalOpUpdate, setModalOpUpdate] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [approveModal, setApproveModal] = useState(false);
	const [email, setEmail] = useState(null);
	const [recoverSimulationId, setRecoverSimulationId] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [openModalRejectAssing, setOpenModalRejectAssing] = useState(null);
	const [generateLink, setGenerateLink] = useState("");
	const [openModalGenereteLink, setOpenModalGenereteLink] = useState(null);
	const [openModalApproveLiquidation, setOpenModalApproveLiquidation] =useState(null);
	const [isApproverTenant, setIsApproverTenant] = useState(false);
	const { fundById } = useFund();
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	const amm = watch("amortization");
	const productForm = watch("product");

	useEffect(() => {
		var currentValues = getValues();
		if (product) {
			var defaultValues = getDefaultValues(product);
			var newDefaultValues = {
				...defaultValues,
				...currentValues,
				amortization: {
					...defaultValues.amortization,
					...currentValues.amortization
				}
			};
			var toRemove = Object.keys(currentValues?.amortization).filter(key => !Object.keys(defaultValues?.amortization).includes(key));
			if (toRemove.length) {
				toRemove.forEach(field => {
					delete newDefaultValues["amortization"][field];
				});
			}

			reset(newDefaultValues);
		}
	}, [product]);

	useEffect(() => {
		if (productForm?.value != product?.value) {
			setProduct(productForm);
		}
	}, [productForm, product]);

	const { errors } = useFormState({ control });

	const isEditing = () => {
		return !!getCreditNoteId();
	};

	const getCreditNoteId = () => props?.location?.search?.replace('?id=', '');

	useEffect(() => {
		if (isEditing()) {
			fetchCreditNoteById();
		} else {
			setTitle("Crédito - Nova operação");
		}
	}, []);

	useEffect(() => {
		const isSameBeneficiary = getValues("sameBeneficiary");
		if (isSameBeneficiary) {
			const [beneficiaryId, beneficiaryDiscriminator, beneficiaryDisplay] = getValues(['personId', 'personDiscriminator', 'personDisplay']);
			setValue("beneficiaryId", beneficiaryId);
			setValue("beneficiaryDiscriminator", beneficiaryDiscriminator);
			setValue("beneficiaryDisplay", beneficiaryDisplay);
		}
	}, [watch("sameBeneficiary")]);

	useEffect(() => {
		if (creditNote) {
			reset({ ...creditNote, product });

		}
	}, [creditNote]);

	useEffect(() => {
		const currentLiquitation = watch("liquidationType");
		if (currentLiquitation == "Invoice") {
			setValue("bankAccountId", null);
		} else if (currentLiquitation == "EletronicTransfer") {
			setValue("invoiceBarCode", null);

		}
	}, [watch("liquidationType")]);

	useEffect(() => {
		const [personId, personDiscriminator] = getValues(['personId', 'personDiscriminator']);
		if (personId) {
			fetchBankAccountsByPersonId(personId, personDiscriminator);
		}
	}, [watch('personId')]);

	useEffect(() => {
		const [beneficiaryId, beneficiaryDiscriminator] = getValues(['beneficiaryId', 'beneficiaryDiscriminator']);
		if (beneficiaryId) {
			fetchBankAccountsByBeneficiaryId(beneficiaryId, beneficiaryDiscriminator);
		}
	}, [watch('beneficiaryId')]);

	const readOnly = (isEditing()) ? !['Draft', 'Error', 'Revision', 'Disapproved'].includes(creditNote?.status) : false;

	const { isFieldRequired, inputBind, inputBindNumber } = getFormFunctions({ validationSchema: getValidationSchema(product), control, errors, setValue, readOnly });
	
	const fetchBankAccountsByPersonId = async (personId, discriminator) => {
		if (discriminator === 'LegalPerson') {
			setValue("bankAccountListLoading", true);
			addLoader("fetchBankAccountsByPersonId");
			getLegalPersonById(personId).then(response => {
				completeLoader("fetchBankAccountsByPersonId");
				setDataNaturalOrLegalPerson(response?.data);
				setBankAccountPerson(response?.data?.bankAccounts || []);
				setValue("bankAccountListLoading", false);
			});
		} else if (discriminator === 'NaturalPerson') {
			setValue("bankAccountListLoading", true);
			addLoader("fetchBankAccountsByPersonId");
			getNaturalPersonById(personId).then(response => {
				completeLoader("fetchBankAccountsByPersonId");
				setDataNaturalOrLegalPerson(response?.data);
				setBankAccountPerson(response?.data?.bankAccounts || []);
				setValue("bankAccountListLoading", false);
			});
		}
	};

	const fetchBankAccountsByBeneficiaryId = async (beneficiaryId, discriminator) => {
		if (discriminator === 'LegalPerson') {
			setValue("bankAccountListLoading", true);
			addLoader("fetchBankAccountsByPersonId");
			getLegalPersonById(beneficiaryId).then(response => {
				completeLoader("fetchBankAccountsByPersonId");
				setBankAccountBeneficiary(response?.data?.bankAccounts || []);
				setValue("bankAccountListLoading", false);
			});
		} else if (discriminator === 'NaturalPerson') {
			setValue("bankAccountListLoading", true);
			addLoader("fetchBankAccountsByPersonId");
			getNaturalPersonById(beneficiaryId).then(response => {
				completeLoader("fetchBankAccountsByPersonId");
				setBankAccountBeneficiary(response?.data?.bankAccounts || []);
				setValue("bankAccountListLoading", false);
			});
		}
	};

	const fetchCreditNoteById = async (id = null) => {
		addLoader("fetchCreditNoteById");
		setTitle("Crédito - Carregando operação");
		return creditNoteService.getCreditNoteById(id ?? getCreditNoteId())
			.then(CreditNote => {
				setTitle(`Crédito - Operação #${CreditNote?.data?.creditNoteNo}`);
				if (CreditNote?.data?.paymentScheduleItems) setSimulationResponse({ ...CreditNote?.data.amortization, ...CreditNote?.data, });
				if (CreditNote?.data?.relatedPersonCreditNote) setInvolvedPersons(CreditNote.data.relatedPersonCreditNote);
				CreditNote.data.bankAccountId = CreditNote.data?.bankAccount?.id;
				if (CreditNote?.data?.uploads) setFiles(CreditNote.data?.uploads);
				var sameBeneficiary = !!(CreditNote?.data?.beneficiaryId == CreditNote?.data?.personId);
				if (CreditNote?.data) {
					CreditNote.data.sameBeneficiary = sameBeneficiary;
					CreditNote.data.amortizationType = product?.amortizationType?.toLowerCase();
					getOptionById(CreditNote.data?.productId, CreditNote.data?.productIdDisplay).then((product) => {
						setValue("product", product);
						setCreditNote(CreditNote?.data);
						completeLoader("fetchCreditNoteById");
					});
					if (isRootTenancy) {
						setIsApproverTenant(true);
					} else {
						CreditNote.data.fundId && fundById(CreditNote.data.fundId).then(resp => {
							const userPoolId = window.__RUNTIME_CONFIG__.REACT_APP_USER_POOL_ID;
							if (resp) setIsApproverTenant(userPoolId == resp.approverTenant);
						});
					}
				} else {
					completeLoader("fetchCreditNoteById");
					Toast.showErrorMessage("Não foi possível obter a operação", "Produto não encontrado.");
					setTitle("Crédito - Erro ao carregar operação");
				}
			}).catch(error => {
				Toast.showErrorMessage("Não foi possível obter a operação", error);
				setTitle("Crédito - Erro ao carregar operação");
				completeLoader("fetchCreditNoteById");
			});
	};

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	const creditNoteId = getCreditNoteId();

	const createDraftPreview = async () => {
		addLoader("createDraftPreview");
		creditNoteService.draftPreviewCreditNoteById(creditNoteId)
			.then(response => {
				fetchCreditNoteById();
				completeLoader("createDraftPreview");
				if (response?.data[0]?.uploads) {
					setFiles(response?.data?.uploads || []);
				} else {
					console.log("Não foi possível gerar o contrato");
				}
			})
			.catch(error => {
				completeLoader("createDraftPreview");
				console.log(error);
			});
	};

	const createAssignmentPreview = async () => {
		addLoader("createAssignmentPreview");
		creditNoteService.assignmentPreviewCreditNoteById(creditNoteId)
			.then(response => {
				fetchCreditNoteById();
				completeLoader("createAssignmentPreview");
				if (response?.data[0]?.uploads) {
					setFiles(response?.data?.uploads || []);
				} else {
					console.log("Não foi possível gerar a CCB");
				}
			})
			.catch(error => {
				completeLoader("createAssignmentPreview");
				console.log(error);
			});
	};

	const filesUpdate = async () => {
		addLoader("filesUpdate");
		await creditNoteService.updateFiles(creditNoteId, files).then(response => {
			if (response?.data?.uploads) {
				completeLoader("filesUpdate");
				setFiles(result => ([
					...result, {
						id: null,
						fileName: "",
						fileType: "",
						displayName: ""
					}
				]));
			} else {
				completeLoader("filesUpdate");
				console.log("Não foi possível atualizar a CCB");
			}
		}).catch(error => {
			completeLoader("filesUpdate");
			console.log(error);
		});
	};

	const handleRecoverSimulateById = async () => {
		addLoader("handleRecoverSimulateById");
		amortizationSimulation.getAmortizationSimulation(recoverSimulationId).then(
			({ data }) => {
				for (const [key, value] of Object.entries(data?.amortization)) {
					setValue(`amortization.${key}`, value);
				}

				const personId = data?.personId;
				const personDisplay = data?.personDisplay;
				const personDiscriminator = data?.personDiscriminator;

				setValue("personId", personId);
				setValue("personDisplay", personDisplay);
				getOptionById(data?.productId).then((product) => {
					setValue("productId", product?.value || '');
					setValue("productIdDisplay", product?.name || '');
					setValue("amortization.amortizationType", product?.amortizationType);
					setValue("product", product);
					setProduct(product);
					completeLoader("handleRecoverSimulateById");
				});
				setSimulationResponse({ ...data.amortization, paymentScheduleItems: data.paymentScheduleItems });
				fetchBankAccountsByPersonId(personId, personDiscriminator);
				completeLoader("handleRecoverSimulateById");
			}
		).catch(error => {
			completeLoader("handleRecoverSimulateById");
			console.log(error);
		});
	};

	const simulateCreditNote = async () => {

		const valid = await trigger();

		if (!valid) {
			return Toast.showErrorMessage('Existem campos inválidos. Preencha antes de prosseguir com a simulação.');
		}
		const skippedMonths = watch("amortization.skippedMonths");
		skippedMonths === null ? setValue("amortization.skippedMonths", []) : skippedMonths;

		const corban = watch("amortization.agentCommission.amount");
		isNaN(corban) ? setValue("amortization.agentCommission.amount", 0) : corban;

		const absInterestInMonths = watch("amortization.absInterestInMonths");
		isNaN(absInterestInMonths) ? setValue("amortization.absInterestInMonths", 0) : absInterestInMonths;

		const productId = getValues("productId");
		const legalPerson = getValues("personDiscriminator") === 'LegalPerson';
		const amortization = { ...amm };
		const personId = getValues("personId");

		setSimulationResponse(null);
		amortizationSimulation.createAmortizationSimulation({ amortization, productId, legalPerson, personId }).then(
			({ data }) => setSimulationResponse(data),
		).catch((err) =>
			console.log('erro', err)
		);
	};

	const sendApprovalCreditNote = async () => {
		addLoader("sendApprovalCreditNote");
		creditNoteService.sumbitApprovalCreditNoteById(getCreditNoteId())
			.then(_ => {
				completeLoader("sendApprovalCreditNote");
				history.push('/ccb');
			})
			.catch(err => {
				completeLoader("sendApprovalCreditNote");

			});
	};

	const sendNotificationCreditNote = async () => {
		console.log('SendNotification ok');
		addLoader("sendNotification");
		creditNoteService.sumbitSendNotificationCreditNoteById(getCreditNoteId())
			.then(_ => {
				completeLoader("sendNotification");
				history.push('/ccb');
			})
			.catch(err => {
				completeLoader("sendNotification");

			});
	};

	const credPayOperationWebhookCreditNote = async () => {
		addLoader('CredPayOperationWebhook');
		creditNoteService.sumbitCredPayOperationWebhookById(getCreditNoteId())
			.then(_ => {
				completeLoader("CredPayOperationWebhook");
				history.push('/ccb');
			})
			.catch(err => {
				completeLoader("CredPayOperationWebhook");

			});
	};

	const handleSendEmail = async () => {
		addLoader("handleSendEmail");
		await creditNoteService.sendAssignmentEmailCreditNoteById(getCreditNoteId(), email)
			.then(response => {
				history.push('/ccb');
				completeLoader("handleSendEmail");
				setSendEmail(false);
			})
			.catch(err => {
				console.log(err);
				completeLoader("handleSendEmail");
				setSendEmail(false);
			});
	};

	const approveliquidCreditNote = async (payload) => {
		addLoader("approveliquidCreditNote");
		creditNoteService.approveliquidCreditNoteById(getCreditNoteId(), payload)
			.then(_ => {
				history.push('/ccb');
				completeLoader("approveliquidCreditNote");
			})
			.catch(err => {
				completeLoader("approveliquidCreditNote");

			});
	};

	const openModalApprove = () => setOpenModalApproveLiquidation(true);

	const signatureCreditNote = async () => {
		addLoader("signatureCreditNote");
		creditNoteService.signatureCreditNoteById(getCreditNoteId())
			.then(_ => {
				history.push('/ccb');
				completeLoader("signatureCreditNote");
			})
			.catch(err => {
				completeLoader("signatureCreditNote");
			});
	};

	const handleDeleteCreditNote = async () => {
		addLoader("handleDeleteCreditNote");
		creditNoteService.deleteCreditNoteById(getCreditNoteId())
			.then(() => {
				history.push('/ccb');
				completeLoader("handleDeleteCreditNote");
			})
			.catch(err => {
				completeLoader("handleDeleteCreditNote");

			});
	};

	const saveCreditNote = (values) => {
		const option = isEditing() ? 'edit' : 'create';
		let record = { ...values };
		const hasCollateral = record?.collateral;
		const hasWarranty = record?.warranty;
		record.relatedPersonCreditNote = involvedPersons;
		record.uploads = files;
		record.assets = creditNote?.assets;

		if (hasCollateral === null && hasWarranty === undefined) {
			Toast.showErrorMessage("Por favor, preencha uma ou mais garantias ou adicione uma obsevação.");
		} else {
			addLoader("saveCreditNote");
			creditNoteService.editOrCreateCreditNote(record, option, selectedTenant)
				.then(result => {
					const [{ data }, successMessage] = result;
					Toast.showSuccessMessage(successMessage);
					completeLoader("saveCreditNote");
					if (isEditing()) {
						fetchCreditNoteById();
					}
					else {
						history.push('/ccb/editar?id=' + data);
					}
				})
				.catch(error => {
					let [err, errorMsg] = error;

					if (err.response) {
						if (err.response?.data.message) {
							const message = err.response.data.message + '</br>' + err?.response?.data?.errors.map(err => {
								if (err.message) {
									return err.message;
								}
								return err;
							});
							Toast.showErrorMessage(message, err);
						} else if (err.response?.data?.errors) {
							Toast.showErrorMessage(err?.response.data?.errors);
						} else {

							var status = err?.response?.statusText;

							Toast.showErrorMessage(errorMsg + ": " + status);
						}
					} else {
						Toast.showErrorMessage(errorMsg);
					}
					completeLoader("saveCreditNote");
				});
		}
	};

	const onCloneCreditNote = () => {

		addLoader("cloneCreditNote");
		creditNoteService.getCreditNoteById(getCreditNoteId()).then(result => {
			creditNoteService.cloneCreditNote(result.data, selectedTenant).then(data => {
				Toast.showSuccessMessage('Operação duplicada com sucesso');
				completeLoader("cloneCreditNote");
				fetchCreditNoteById(data);
				history.push('/ccb/editar?id=' + data);

			}).catch(err => {
				Toast.showErrorMessage("Não foi possível clonar a operação");
				completeLoader("cloneCreditNote");
				console.log(err);
			});

		}).catch(err => {
			Toast.showErrorMessage("Não foi possível clonar a operação");
			completeLoader("cloneCreditNote");
			console.log(err);
		});
	};

	const paymentRevisionCreditNote = async () => {
		addLoader("paymentRevisionCreditNote");
		creditNoteService.paymentRevisionCreditNoteById(getCreditNoteId())
			.then(_ => {
				history.push('/ccb');
				completeLoader("paymentRevisionCreditNote");
			})
			.catch(err => {
				completeLoader("paymentRevisionCreditNote");

			});
	};


	const generatePaymentLink = async () => {
		addLoader("generatePaymentLink");

		const valueTotalInCents = simulationResponse?.paymentScheduleItems?.map(x => x.payment).reduce((a, b) => a + b);

		let dataGeneratePaymentLink = {
			customerName: dataNaturalOrLegalPerson?.name,
			numberOfPayments: creditNote?.amortization?.termInMonths,
			customerEmail: dataNaturalOrLegalPerson?.email, 
			customerCellphone: dataNaturalOrLegalPerson?.phone, 
			totalValueInCents: valueTotalInCents,
			registrationNumber: dataNaturalOrLegalPerson?.registrationNumber,
			description: creditNote?.creditNoteNo
		};

		creditNoteService.generatePaymentLink(dataGeneratePaymentLink)
			.then(result => {
				completeLoader("generatePaymentLink");
				setOpenModalGenereteLink(true);
				setGenerateLink(result?.data);
				Toast.showSuccessMessage('Sucesso! Link gerado com sucesso.');
			}).catch(err =>	
				completeLoader("generatePaymentLink")
			);
	};

	const showSendApproval = (status) => {
		if (
			status &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_SEND_CREDIT_APPROVAL &&
			(['Disapproved', 'Error', 'Draft', 'Revision'].includes(status))
		) return true;

		return;

	};

	const showSendNotification = (status) => {
		if (
			status &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_SEND_CREDIT_APPROVAL &&
			(['Disapproved', 'Error', 'Draft', 'Revision'].includes(status))
		) return true;

		return;

	};

	const showSimulate = (status) => {
		if (
			status &&
			(['Disapproved', 'Error', 'Draft', 'Revision'].includes(status))
		) return true;
		return;
	};

	const showApproveAndOnReject = (status) => {

		if (
			status &&
			isApproverTenant &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_APPROVE_REJECT_CREDIT &&
			(isRootTenancy ? ['ComplianceApproval', 'CreditApproval'] : ['CreditApproval']).includes(status)
		) return true;
		return;
	};



	const showApproveInstrumentAndOnReject = (status) => {
		if (
			status &&
			isRootTenancy &&
			!!+window.__RUNTIME_CONFIG__.REACT_APP_APPROVE_REJECT_CREDIT &&
			['InstrumentApproval'].includes(status)

		) return true;
		return;
	};

	const showButtonCancel = (status) => {
		if (
			isRootTenancy ? status &&
				(['InstrumentApproval', 'Signatures', 'Liquidation', 'ComplianceApproval', "CreditApproval", "Revision", "SignaturesValidation"].includes(status)) :
				(['InstrumentApproval', 'Signatures', 'ComplianceApproval', "CreditApproval", "Revision"].includes(status))
		) return true;
		return;

	};

	const handleCloseModal = () => {
		setOpenModal(null);
		setRecoverSimulationId(null);
		setEmail(null);
		setSendEmail(false);
		setApproveModal(false);
		setOpenModalRejectAssing(false);
		setModalOpUpdate(false);
		setModalAssign(false);
		setModalDelete(false);
	};

	const handleApproveModal = () => {
		setApproveModal(true);
	};

	const handleOpenRejectAssignModal = () => {
		setOpenModalRejectAssing(true);
	};

	const handleCancelModal = () => {
		setOpenModal(true);
	};

	const handleRejectModal = () => {
		setOpenModal(false);
	};

	const handleModalSendEmail = () => {
		setSendEmail(true);
	};

	const handleDeleteModal = () => {
		setModalDelete(true);
	};

	const handleSimulationIdModal = () => {
		setRecoverSimulationId(true);
	};

	const handleAssignModal = () => {
		setModalAssign(true);
	};

	const handleModalOpUpdate = () => {
		setModalOpUpdate(true);
	};
	
	const previewDraft = creditNote?.uploads?.find(element => element.fileType === "Draft" && element.displayName.includes(".pdf"));

	const previewAssignment = creditNote?.uploads?.find(element => element.fileType === "Assignment" && element.displayName.includes(".pdf"));

	const showGeneratePayment = (paramGenerate) => {
		let caseIncludeStatus = ['Draft', 'ComplianceApproval', 'CreditApproval'].includes(String(paramGenerate?.status));  
		if (caseIncludeStatus || paramGenerate?.productIdDisplay?.includes('creditCard')) {
			return true;
		} else {
			return false;
		}
	};

	const showUpdateFiles = (statusUpdate) => {
		let caseIncludeStatus = ['SignaturesValidation', 'Finished', 'Liquidation', 'ComplianceApproval', 'PaymentRevision', 'CreditApproval', 'InstrumentApproval'].includes(String(statusUpdate?.status));
    	if (caseIncludeStatus && isRootTenancy) {
			return true;
		} else {
			return false;
		}
	}; 

	const actions = isEditing() ? {
		onAssign: handleAssignModal,
		onManualUpdate: handleModalOpUpdate,
		onPaymentRevision: creditNote?.status === 'PaymentRevision' ? paymentRevisionCreditNote : false,
		onCancel: showButtonCancel(creditNote?.status)  ? handleCancelModal : false,
		onRecoverSimulate: creditNote?.status === 'Draft' ? handleSimulationIdModal : false,
		onUpdateFiles: creditNote?.status === 'Signatures' || showUpdateFiles(creditNote) ? filesUpdate : false,
		onCreateDraft: creditNote?.status === 'Draft' ? createDraftPreview : false,
		onPreview: creditNote?.status === 'Draft' ? previewDraft : false,
		onCreateAssignment: creditNote?.status === 'Draft' ? createAssignmentPreview : false,
		onAssignmentPreview: creditNote?.status === 'Draft' ? previewAssignment : false,
		onDelete: handleDeleteModal,
		onSendApproval: showSendApproval(creditNote?.status) ? sendApprovalCreditNote : false,
		onGeneratePaymentLink: showGeneratePayment(creditNote) ? generatePaymentLink : false, 
		onSendNotification: creditNote?.status === 'Signatures' ? sendNotificationCreditNote : false,
		onCredPayOperationWebhook: creditNote?.status === 'CreditApproval' ||  creditNote?.productIdDisplay?.includes('creditCard')? credPayOperationWebhookCreditNote : false,
		onSendEmail: creditNote?.status === 'Draft' || creditNote?.status === "Signatures" ? handleModalSendEmail : false,
		onApprove: showApproveAndOnReject(creditNote?.status) ? handleApproveModal : false,
		OnApproveInstrument: showApproveInstrumentAndOnReject(creditNote?.status) ? handleApproveModal : false,
		onReject: creditNote?.status === 'Liquidation' || creditNote?.status === 'ManualLiquidation' || !!(showApproveAndOnReject(creditNote?.status) || showApproveInstrumentAndOnReject(creditNote?.status)) ? handleRejectModal : false,
		onApproveLiquid: creditNote?.status === 'Liquidation' || creditNote?.status === 'ManualLiquidation' ? openModalApprove : false,
		onSignature: creditNote?.status === 'Signatures' ? signatureCreditNote : false,
		onApproveSignature: creditNote?.status === 'SignaturesValidation' ? signatureCreditNote : false,
		onRejectSignature: creditNote?.status === 'SignaturesValidation' ? handleOpenRejectAssignModal : false,
		onSimulate: showSimulate(creditNote?.status) ? simulateCreditNote : false,
		onClone: onCloneCreditNote,
		disableOnApproveInstrument: !showApproveInstrumentAndOnReject(creditNote?.status),
		disableApproveOrReject: !showApproveAndOnReject(creditNote?.status),
		disabledAll: loading,
		isDirty: isDirty
	} : {
		onRecoverSimulate: handleSimulationIdModal,
		onSimulate: simulateCreditNote
	};

	const taker = { personDiscriminator: watch("personDiscriminator"), personId: watch("personId"), personIdDisplay: watch('personDisplay') };

	const filterInvolvedPersons = creditNote?.relatedPersonCreditNote.filter((value) => {
		return value.typeOfRelationship != "Witness";
	});

	const hiddenEnvolved = productForm?.amortizationType == "FGTS" && productForm?.isConsumerCreditNote == false;

	const tabs = [
		{
			name: 'Informações',
			show: true,
			children: <InformationsCredit {...{ inputBind, inputBindNumber, creditNote, control, setValue, getValues, watch, readOnly, bankAccountPerson, bankAccountBeneficiary, isFieldRequired, setInvolvedPersons }} />
		},
		!hiddenEnvolved && {
			name: 'Envolvidos',
			show: true,
			children: <InvolvedPersons {...{ inputBindNumber, isFieldRequired, inputBind, simulationResponse, involvedPersons, setInvolvedPersons, readOnly }} />
		},
		{
			name: 'Operação',
			show: true,
			children: <OperationsContent {...{ product: watch("product"), creditNote, readOnly, watch, inputBindNumber, isFieldRequired, inputBind, simulationResponse, getValues, setValue }} />
		},
		productForm?.useCollateral == true && {
			name: 'Garantias',
			show: true,
			children: <WarrantyTab {...{ inputBind, inputBindNumber, creditNote, control, setValue, getValues, watch, readOnly }} />
		},
		{
			name: 'Documentos',
			show: true,
			children: <UploadContent  {...{ readOnly: readOnly && creditNote?.status != "InstrumentApproval", files, setFiles, taker, getRootProps, getInputProps, involvedPersons: filterInvolvedPersons }} />
		},
		{
			name: 'KYC',
			show: !!+window.__RUNTIME_CONFIG__.REACT_APP_RISK_TAB && isRootTenancy && isEditing(),
			children: <KYC {... { creditNoteId: getCreditNoteId(), isEditing, setLoading: (side) => { side ? addLoader("KYC") : completeLoader("KYC"); }, involvedPersons: filterInvolvedPersons, taker }} />
		},
		{
			name: 'Crédito',
			show: isEditing(),
			children: <CreditNoteDatasets
				creditNoteId={getCreditNoteId()}
				loading={loading}
				setLoading={(side) => { side ? addLoader("Credit") : completeLoader("Credit"); }}
				taker={taker}
				involvedPersons={filterInvolvedPersons}
			/>
		},
		{
			name: 'Linha do tempo',
			show: isEditing(),
			children: <RecordTimeline data={creditNote?.timeline} />
		}
	];

	const onSubmit = (values) => {
		saveCreditNote(values);
	};

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log("onError CreditNoteCreateEdit", values);
	};

	const style = { borderTopLeftRadius: 10, borderTopRightRadius: 7, borderBottomLeftRadius: 10, borderBottomRightRadius: 7, backgroundColor: 'white', padding: 10, margin: 1 };

	return (
		<ContentContainer>
			{modalAssign &&
				<ModalAssign title='Selecione um operador ou grupo' recordType={"CreditNote"} recordId={getCreditNoteId()} onCloseModal={handleCloseModal} />
			}

			{openModalGenereteLink && 
				<ModalComponent
					open={open}
					onClose={() => setOpenModalGenereteLink(false)}
					title="Link de pagamento gerado"
					subtitle={'Copie o link de pagamento abaixo para sua área de transferência.'}
					enableButton={false}
					children={<ModalGenereteLink onClose={() => setOpenModalGenereteLink(false)} link={generateLink}/>}
				/>
			}
			{openModalApproveLiquidation && 
				<ModalComponent
					open={open}
					onClose={() => setOpenModalApproveLiquidation(false)}
					title="Aprovar liquidação"
					subtitle='Conclua a etapa de Liquidação para encerrar a operação'
					enableButton={false}
					children={<ModalApproveLiquidation 
						isLoading={loading} 
						approveliquidCreditNote={approveliquidCreditNote}
					/>
					}
				/>
			}

			{modalOpUpdate &&
				<ModalManualUpdate
					open={modalOpUpdate}
					recordType={"CreditNote"}
					recordId={getCreditNoteId()}
					handleOpen={handleModalOpUpdate}
					onCloseModal={handleCloseModal} />
			}
			{openModal != null &&
				<ModalBlock onCloseModal={handleCloseModal} id={getCreditNoteId()} openModal={openModal} recordType={openModal === false ? "onReject" : ""} />
			}
			{approveModal &&
				<ModalBlock onCloseModal={handleCloseModal} id={getCreditNoteId()} openModal={approveModal} recordType={creditNote.status} />
			}
			{openModalRejectAssing &&
				<ModalBlock onCloseModal={handleCloseModal} id={getCreditNoteId()} openModal={openModalRejectAssing} recordType={creditNote?.status} />
			}

			{sendEmail &&
				<>
					<ModalWithLoading title='Destinatário' onCloseModal={handleCloseModal}>
						<Grid container direction='column' justifyContent='center' spacing={2}>
							<Grid item >
								<p>Será enviado e-mail ao destinatário com toda a documentação da operação.</p>
								<TextField rows={1} label="Para" fullWidth onChange={(event) => setEmail(event.target.value)}></TextField>
							</Grid>
							<Grid item>
								<Button disabled={!email?.length} color="secondary" variant='contained' onClick={() => handleSendEmail()}>Enviar</Button>
							</Grid>
						</Grid>
					</ModalWithLoading>
				</>
			}

			{recoverSimulationId &&
				<>
					<ModalWithLoading title='Recuperar Simulação' onCloseModal={handleCloseModal}>
						<Grid container direction='column' justifyContent='center' spacing={2}>
							<Grid item >
								<p>Insira um ID para recuperar a simulação.</p>
								<TextField rows={1} label="ID" fullWidth onChange={(event) => setRecoverSimulationId(event.target.value)}></TextField>
							</Grid>
							<Grid item>
								<Button disabled={!recoverSimulationId?.length} color="secondary" variant='contained' onClick={() => handleRecoverSimulateById()}>Enviar</Button>
							</Grid>
						</Grid>
					</ModalWithLoading>
				</>
			}
			{modalDelete &&
				<>
					<ModalWithLoading title='Tem certeza que deseja excluir essa operação?' onCloseModal={handleCloseModal}>
						<Grid container direction='column' justifyContent='center' spacing={2}>
							<Grid item>
								<Button color="secondary" variant='contained' onClick={() => handleDeleteCreditNote()}>Excluir</Button>
							</Grid>
						</Grid>
					</ModalWithLoading>
				</>
			}
			{loading && (
				<div id="spinner"><i className="fa fa-spinner" color="#FFF" size={100} /></div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<ActionBar {...actions} />

				<Grid
					item
					style={{
						display: 'block',
						position: 'relative',
					}}
				>
					{isEditing() && <>
						{isDirty && <Grid item>
							<Alert style={{ position: "absolute", bottom: '30px' }} severity="warning">Existem informações não salvas</Alert>
						</Grid>}</>
					}
					{isEditing() && <div className="disablePrint">
						<Box p={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{creditNote?.statusDisplay && <small style={style}><strong>Status:</strong>&nbsp;{creditNote?.statusDisplay}</small>}
							{creditNote?.createdAt && <small style={style}><strong>Criado em:</strong>&nbsp;{creditNote?.createdAt && new Date(creditNote?.createdAt).toLocaleDateString('pt-BR')}</small>}
							{creditNote?.ownerUser && <small style={style}><strong>Operador:</strong>&nbsp;{creditNote?.ownerUser?.userIdDisplay}</small>}
							{creditNote?.ownerGroup && <small style={style}><strong>Grupo:</strong>&nbsp;{creditNote?.ownerGroup?.groupDisplay}</small>}
						</Box>
					</div>}
				</Grid>

				<div className="disablePrint">
					<AppBar position="relative" color="transparent" elevation={0}>
						<Tabs
							centered={false}
							value={tab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							{tabs.map((item, index) => (
								item.show && <Tab value={item.name} key={index} label={item.name} {...a11yProps(index)} />
							))}
						</Tabs>
					</AppBar></div>
				{
					tabs.map((item, index) =>
						<TabPanel key={index} value={tab} index={item.name}>
							{item.children}
						</TabPanel>
					)
				}
			</form>
		</ContentContainer>
	);
}

export default CreditNoteCreateEdit;