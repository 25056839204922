import React, { useState } from 'react';
import { ContentContainer, Toast } from 'components';
import MaterialTable from 'material-table';
import { localizationOptions } from 'helpers/table.helpers';
import { Grid, TextField, Box, Button, TablePagination, Select, MenuItem, Typography } from '@material-ui/core';
import TextFieldComponent from 'components/TextInput/TextField';
import { getBankAccountRequestList } from 'services/bankAccount';
import { Link, useHistory } from 'react-router-dom';
import { useHeader } from 'contexts/header';
import { approveBankAccountById, disapprovedBankAccountById } from 'services/bankAccount';
import { validationSchema, defaultValues } from 'pages/BankAccountRequest/ModalApproveAndReject/schema/validation';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getFormFunctions from 'helpers/form-helpers';
import { ThumbUpOutlined, ThumbDownOutlined, InsertDriveFileOutlined } from '@material-ui/icons';
import AnalysisStatusColumnComponent from 'components/DastasetsStatusColumn/ColumnsStatus';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTenant } from 'contexts/tenant';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SelectComponent from "components/Select/Select";
import { DatePicker } from 'components';
import { useBlockReason } from 'contexts/blockReason';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import ScoreSale from 'components/Datasets/ScoreClearSale/ScoreSale';
import MultiSelectComponent from 'components/MultiSelect/MultiSelect';
import ModalComponent from 'components/Modal/ModalComponent';
import Helper from 'helpers/format.helpers';

const ActionBar = (props) => {
	const {
		onApprove,
		onReject,
		disableApproveOrReject = false,
		disabledAll,
		onRefresh, 
		selectedRows
	} = props;
	const disabledOnreject = (onApprove && disableApproveOrReject);

	return (
		<Box mb={2} className="disablePrint">
			<Grid container spacing={1}>
				{onRefresh &&
					<Grid item>
						<Button className="button-actionBar" variant="contained" onClick={onRefresh} style={{ backgroundColor: "#5F5F5F" }}>
							<RefreshOutlined style={{ color: "white" }} /><Typography component="span" style={{ color: "white", textTransform: 'none', }} >&nbsp;Atualizar</Typography>
						</Button>
					</Grid>}

				{onApprove && <Grid item>
					<Button className="button-actionBar" color="primary" variant="contained" disabled={disabledOnreject} onClick={onApprove || disabledAll}>
						<ThumbUpOutlined />&nbsp;Aprovar
					</Button>
				</Grid>}

				{onReject && <Grid item>
					<Button className="button-actionBar" color="secondary" variant="contained" disabled={disabledOnreject} onClick={onReject || disabledAll}>
						<ThumbDownOutlined />&nbsp;Rejeitar
					</Button>
				</Grid>}
			</Grid>
		</Box >

	);
};


const statusFiltering = [
	{
		name: 'Rascunho',
		value: 'Draft'
	},
	{
		name: 'Pré-Análise Corban',
		value: 'CorbanPreAnalysis'
	},
	{
		name: 'Assinatura',
		value: 'Signatures'
	},
	{
		name: 'Aprovação',
		value: 'Approval'
	},
	{
		name: 'Revisão',
		value: 'Revision'
	},
	{
		name: 'Revisão de Abertura de Conta',
		value: 'OpeningAccountRevision'
	},
	{
		name: 'Ativa',
		value: 'Active'
	},
	{
		name: 'Abertura',
		value: 'Processing'
	},
	{
		name: 'Expirado',
		value: 'Timeout'
	},
	{
		name: 'Encerrado',
		value: 'Closed'
	},
	{
		name: 'Cancelada',
		value: 'Cancel'
	}
];

const TypeAccount = [
	{
		name: 'Conta de Livre Movimentação',
		value: 'Payment'
	},
	{
		name: 'Escrow',
		value: 'Escrow'
	},
];

const BankAccountList = (props) => {

	const { hideActions } = props;
	const history = useHistory();
	const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage') || 5);
	const { setTitle } = useHeader();
	const { blockReasonList } = useBlockReason();
	const [selectedRows, setSelectedRows] = useState([]);
	const [selected, setSelected] = useState([]);
	const { reset, setValue, control, handleSubmit, watch } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: defaultValues
	});
	const [openModalApproveAndReject, setOpenModalApproveAndReject] = useState(null);
	const [enableFilter, setEnableFilter] = useState(false);
	const { selectedTenant } = useTenant();
	const tableRef = React.useRef(null);

	const useComplianceAnalysis = !!+window.__RUNTIME_CONFIG__.REACT_APP_USE_COMPLIANCE_ANALYSIS_DATASET;
	const isRootTenancy = window.__RUNTIME_CONFIG__.REACT_APP_TENANT_TYPE == '0';

	const { errors } = useFormState({ control });

	const { inputBind } = getFormFunctions({ validationSchema, setValue, control, errors });

	const onRowsPerPageChange = (page) => {
		setRowsPerPage(page);
		localStorage.setItem('rowsPerPage', page);
	};

	const handleClose = () => {
		setOpenModalApproveAndReject(null);
		reset(defaultValues);
	};

	const handleModalApprove = () => {
		setValue('approve', true);
		setOpenModalApproveAndReject(true);
	};

	const handleModalReject = () => {
		setValue('approve', false);
		setOpenModalApproveAndReject(false);
	};

	const approve = (values) => {
		const { message } = values;

		selectedRows.map(row => {
			approveBankAccountById(row?.id, message)
				.then(_ => {
					tableRef.current.onQueryChange();
					handleClose();
				});
		});
		setSelectedRows([]);
	};

	const reject = (values) => {
		const { message, reasonDisapproval, blockType, blockUntil } = values;

		selectedRows.map(row => {
			disapprovedBankAccountById(row?.id, { message, reasonDisapproval, blockType, blockUntil })
				.then(_ => {
					tableRef.current.onQueryChange();
					handleClose();
				});
		});
		setSelectedRows([]);
	};

	const status = selectedRows.map(row => row.status);

	const showApproveAndReject = (status) => {

		const lengthAllStatusThatIncludesString = status.filter(st => ['Approval'].includes(st)).length;
		if (
			status?.length > 0 &&
			isRootTenancy &&
			status.length === lengthAllStatusThatIncludesString
		) return true;
		return;
	};

	const actions = {
		onApprove: handleModalApprove,
		onReject: handleModalReject,
		disableApproveOrReject: !showApproveAndReject(status),
		onRefresh: () => tableRef?.current?.onQueryChange(),
		selectedRows: selectedRows
	};

	const onSubmit = (values) => {
		openModalApproveAndReject == true ? approve(values) : reject(values);
	};

	const onError = (values) => {
		Toast.showErrorMessage("Há campos inválidos, por favor verifique os valores digitados.");
		console.log(values);
	};

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setValue("reasonDisapproval", value);
		setSelected(
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<ContentContainer>
			<ActionBar {...actions} hideSubmit={true} />
			{openModalApproveAndReject != null &&
				<ModalComponent
					title={openModalApproveAndReject ? 'Aprovar solicitação de conta' : 'Reprovar solicitação de conta'}
					subtitle={openModalApproveAndReject ? "tem certeza que deseja aprovar a solicitação?" : "tem certeza que deseja reprovar a solicitação?"}
					onClose={handleClose}
					open={true}
					enableButton={false}
					children={
						<>
							<form
								onSubmit={handleSubmit(onSubmit, onError)}>
								{openModalApproveAndReject == false &&
									<>
										<Grid container spacing={4}>
											<Grid item xs={12}>
												<MultiSelectComponent
													id="reasonDisapproval"
													label="Motivo da Reprovação"
													value={selected || []}
													onChange={handleChange}
													fields={blockReasonList}
													{...inputBind("reasonDisapproval")}
													required={true}
												/>
											</Grid>
											<Grid item xs={12}>
												<SelectComponent
													id="blockType"
													name="blockType"
													label="Tipo de Bloqueio"
													{...inputBind("blockType")}
													defaultValue={null}
													required={true}
													fields={[
														{ name: 'Ajustável', value: 'Adjustable' },
														{ name: 'Final', value: "Final" },
													]} />
											</Grid>
											{watch("blockType") == 'Final' &&
												<Grid item xs={12}>
													<DatePicker
														id="blockUntil"
														label="Data de Bloqueio"
														variant="inline"
														name="blockUntil"
														fullWidth
														disablePast={true}
														InputLabelProps={{ shrink: true, required: true }}
														{...inputBind("blockUntil")}
														required={true} />
												</Grid>}
										</Grid>
									</>}
								<Grid container spacing={5}>
									<Controller
										render={({ field }) => (
											<>
												<Grid item xs={12}>
													<TextFieldComponent
														id="message"
														fullWidth
														type="text"
														name="message"
														component={TextField}
														label="Observações"
														margin="dense"
														multiline={true}
														rows={4}
														fieldAreaHeight={110}
														{...inputBind("message")}
														required={watch('reasonDisapproval') == 'OUTROS_MOTIVOS' ? true : false}
													/>
												</Grid>
											</>)}
										control={control}
										name="message" />
								</Grid>
								<Grid container justifyContent='center' spacing={10}>
									<Grid item >
										<Button color={openModalApproveAndReject ? "primary" : "secondary"} variant='contained' type='submit' fullWidth size='large'>
											<Typography style={{ textTransform: 'none' }} component="span" >
												{openModalApproveAndReject ? 'Aprovar' : 'Reprovar'}
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</form>
						</>
					}
				/>}

			{!hideActions && <>
				<div className='uk-width-auto@m uk-width-1-1'>
					<div className='uk-width-auto@m uk-width-1-1'>
						<Link to='/solicitacao-conta-digital/nova' style={{ backgroundColor: '#3f51b5', textDecoration: "none" }} className='wt-button wt-button-lowercase uk-button'>
							<i className='fa fa-plus uk-margin-small-right' style={{ color: "white" }}></i>

							<Typography component="span" style={{ color: "white", textTransform: 'none', }}>Adicionar nova conta digital</Typography>
						</Link>
						<Button style={{ marginLeft: 10 }} onClick={() => setEnableFilter(!enableFilter)} >
							<FilterListIcon /> Filtrar
						</Button>
					</div>
				</div>
				<br />
			</>
			}

			<MaterialTable
				tableRef={tableRef}
				title='Solicitações'
				columns={[
					isRootTenancy ? { title: 'Correspondente', field: 'tenantDisplay', filtering: false } : undefined,
					{ title: 'Nome do Parceiro', field: 'partnerName' },
					{ title: 'CPF/CNPJ', field: 'ownerRegistrationNumber', render: ({ ownerRegistrationNumber }) => Helper.formatDocumentNumber(ownerRegistrationNumber) },
					{ title: 'Solicitante', field: 'ownerDisplay', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 500 } },
					{
						title: 'Tipo de conta', field: 'typeDisplay',
						filterComponent: (props) => <Select
							{...props}
							fullWidth
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
							component={TextField}
						>
							<MenuItem value={""}></MenuItem>
							{TypeAccount && TypeAccount.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					},
					{
						title: 'Status',
						field: 'statusDisplay',
						filterComponent: (props) => <Select
							{...props}
							fullWidth
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
							component={TextField}
						>
							<MenuItem value={""}></MenuItem>
							{statusFiltering && statusFiltering.map(field => (
								<MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
							))}
						</Select>
					},
					{ title: 'Nº Conta', field: 'bankAccountNumber', filtering: false },
					{
						title: 'Criado em', field: 'createdAt',
						render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString('pt-BR'),
						filterComponent: (props) => <KeyboardDatePicker
							{...props}
							format="dd/MM/yyyy"
							InputLabelProps={{ shrink: true }}
							placeholder="dd/mm/aaaa"
							variant="inline"
							value={props?.columnDef?.tableData?.filterValue || null}
							disableFuture={true}
							onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e)}
							helperText={false}
						/>
					},
					useComplianceAnalysis ? {
						title: 'Análise de Compliance', field: 'statusMotorAnalysis', filtering: false, render: (props) =>
							<AnalysisStatusColumnComponent endpoint={'BankAccountRequest'} row={props} datasetName="complianceanalysis" />
					} : undefined,

					isRootTenancy ? {
						title: 'Score de Risco',
						render: (props) => <ScoreSale discriminator={props.ownerDiscriminator} id={props.ownerId} />
					} : undefined
				].filter(x => x !== undefined)}
				actions={[
					{
						icon: 'search',
						tooltip: 'Editar',
						position: 'row',
						onClick: (_, rowData) => { history.push(`/solicitacao-conta-digital/editar?id=${rowData.id}`); }
					},
				]}
				data={(allParams) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, search, filters, orderBy, orderDirection } = allParams;
						console.log(`all`, allParams);
						const createdAt = filters.find(f => f.column.field === 'createdAt')?.value;

						const filterFinalDate = enableFilter &&
							createdAt &&
							moment(createdAt).isValid() &&
							moment(createdAt).set("hour", "23").set("minute", "59")?._d;

						const filterInitialDate =
							enableFilter &&
							createdAt &&
							moment(createdAt).isValid() &&
							moment(createdAt).set("hour", "0").set("minute", "0")?._d;

						const filtersValues = {
							partnerName: enableFilter && filters.find(f => f.column.field === 'partnerName')?.value,
							ownerDisplay: enableFilter && filters.find(f => f.column.field === 'ownerDisplay')?.value,
							registrationNumber: enableFilter && filters.find(f => f.column.field === 'ownerRegistrationNumber')?.value,
							initialDate: filterInitialDate,
							status: enableFilter && filters.find(f => f.column.field === 'statusDisplay')?.value,
							typeAccount: enableFilter && filters.find(f => f.column.field === 'typeDisplay')?.value,
							finalDate: filterFinalDate,
							page,
							size: pageSize,
							searchString: search,
							orderByField: orderBy?.field,
							orderByDirection: orderDirection
						};

						getBankAccountRequestList(filtersValues, selectedTenant)
							.then(result => {
								setTitle("Contas digitais");
								if (result?.data)
									resolve({
										data: result.data,
										page: result.page,
										totalCount: result.totalItems,
									});
								else {
									resolve({
										data: [],
										page: 0,
										totalCount: 0,
									});
								}
							}).catch(err => reject(err));
					})
				}
				onChangeRowsPerPage={onRowsPerPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				localization={localizationOptions}
				components={{
					Pagination: props => (
						<TablePagination
							{...props}
							rowsPerPageOptions={[5, 10, 20, 50]}
						/>
					),
				}}
				options={{
					selection: true,
					search: true,
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					debounceInterval: 500,
					searchAutoFocus: true,
					filtering: enableFilter
				}}
				onSelectionChange={(rows) => setSelectedRows(rows)}
			/>
		</ContentContainer>
	);
};

export default BankAccountList;
